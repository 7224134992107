/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */

import React, { FC, useEffect, useState } from "react"
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil"
import { message } from "antd"
import { initialData, selectedItemID, userDesigns } from "../../../state/atoms"
import { ApiClient } from "../../../../common/api/ApiClient"
import Loading, { LoadingContent } from "../../../../common/components/Loading"
import useGetDebugVariables from "./utils/useGetDebugVariables"
import { isSaveInProgressAtom, sceneAtom } from "../../../state/scene/atoms"
import { useLocation, useParams } from "react-router-dom"
import { DesignsApi } from "../../../../common/api/firebase/designs"
import { SceneType } from "../../../state/scene/types"
import { PartTypeEnum, SegmentedTubeValues } from "../../../utils/Types"
import GPUBench from "./debug/GPUBench"
import History from "./History"
import MainLayout from "./mainLayout/MainLayout"
import Scene from "./scene/Scene"
import { useGetData } from "./utils/getData"
import HistoryLogger from "./History"
import { events, useEventsData } from "../../../../common/utils/rudderAnalyticsUtils"
import { authState } from "../../../../common/state/LoginState/RecoilAuthState"
import { useUpdateUnit } from "../../../state/scene/setters"
import { messageUtils } from "./scene/LowerRightMessages"

const useDataProvider = () => {
    const initialData = useGetData({ getData: ApiClient.getInitialData, query: "", })
    if (initialData.error) {
        throw initialData.error
    }
    return initialData.data
}

const useApp = () => {
    const setInitialData = useSetRecoilState(initialData)
    const data = useDataProvider()
    const [loading, setLoading,] = useState(true)
    useEffect(() => {
        if (data) {
            setInitialData(data)
            setLoading(false)
        }
    }, [data,])

    return {
        loading,
    }
}

const InitialDataProvider: FC<{ children: React.ReactNode, }> = ({ children, }) => {
    const { loading, } = useApp()
    const isSaveInProgress = useRecoilValue(isSaveInProgressAtom)
    if (loading) {
        return <LoadingContent><Loading />Loading assets</LoadingContent>
    }
    if (isSaveInProgress) {
        return <LoadingContent><Loading />Saving design...</LoadingContent>
    }
    return <>{children}</>
}

const DesignScreen = () => {
    const [loaded, setLoaded,] = useState(false)
    const { getVariables, } = useGetDebugVariables()
    const setSceneAtom = useSetRecoilState(sceneAtom)
    const resetSceneAtom = useResetRecoilState(sceneAtom)
    const resetSelectedPart = useResetRecoilState(selectedItemID)
    const allParts = useRecoilValue(initialData)
    const { userId, designId, } = useParams()
    const location = useLocation()
    const eventsData = useEventsData()
    const { user, } = useRecoilValue(authState)
    const updateUnit = useUpdateUnit()
    const setIsSaveInProgress = useSetRecoilState(isSaveInProgressAtom)

    useEffect(() => {
        setTimeout(() => {
            messageUtils.custom("We rebuilt a big chunk of the app to be much faster!", {
                duration: 8,
            })
        }, 8000)
        setTimeout(() => {
            messageUtils.custom("Next up is being able to change width and height of lumber parts!", {
                duration: 5,
            })
        }, 16000)
    }, [])

    const migratePartData = (partFromJson: SegmentedTubeValues) => {
        const partFromAPI = allParts?.parts.find(p => p.id === partFromJson.apiTypeId)
        partFromJson.markers.forEach(marker => {
            const markerFromAPI
                = partFromAPI?.connections.find(m => m.placeholderId === marker.name)
            if (markerFromAPI && markerFromAPI.boundary) {
                marker.boundary = markerFromAPI.boundary
            }
        })
    }

    const load = async (userId: string, designId: string) => {
        try {
            resetSelectedPart()
            const design = await DesignsApi.getDesignById(userId, designId)
            const state = JSON.parse(design.state) as SceneType

            state.partsIds.forEach(id => {
                const part = state.parts[id.id]
                if (part) {
                    part.loaded = true
                    if (part.type === PartTypeEnum.connector) {
                        part.instanciated = false
                        if (!part.rotationMarkerName) {
                            part.rotationMarkerName = part.initialMarkerName
                        }
                    }
                    if (part.type === PartTypeEnum.segmentedTube) {
                        if (!part.lengthNegativeSide) {
                            part.lengthNegativeSide = 0
                        }
                        migratePartData(part)
                    }
                }
            })
            setIsSaveInProgress(false)
            setSceneAtom({ ...state, name: design.name, })
            setLoaded(true)
        } catch (e: any) {
            setIsSaveInProgress(false)
            message.error("Couldn't load the design")
            throw new Error("Couldn't load the design", e)
        }
    }

    useEffect(() => {
        if (!designId) {
            events.initialDesign(eventsData())
        }
    }, [])


    useEffect(() => {
        const fetchDesigns = async () => {
            if (user) {
                const userDesigns = await DesignsApi.fetchUserDesigns(1)
                if (userDesigns.length && userDesigns[0].unit) {
                    updateUnit(userDesigns[0].unit)
                }
            }
        }
        fetchDesigns()
    }, [user, location.pathname,])

    useEffect(() => {
        if (allParts) {
            if (userId && designId) {
                load(userId, designId)
            } else {
                setLoaded(true)
            }
        }
        return () => resetSceneAtom()
    }, [location.pathname, allParts,])

    return <InitialDataProvider>
        {getVariables().gpuData && getVariables().gpuData === "TRUE" && <GPUBench />}
        <History loaded={loaded} />
        {
            loaded
                ? <MainLayout scene={<Scene />} />
                : null
        }
    </InitialDataProvider>
}

export default DesignScreen