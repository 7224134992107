import { Object3D, Vector3 }
    from "three"
import { convertInToCm } from "../../../../../../utils/UnitUtils"
import { FreePositions } from "../../../../../../../../../providers/moveProvider/types"
import { MeshStrategy } from "../../../../../../../../../providers/moveProvider/meshHelpers"

function getClosestPointsInDirection(pointsObjects: FreePositions[],
    center: Vector3,
    localDirection: Vector3,
    startLength = 0,
    endLength = 0,
    group: Object3D) {
    const worldDirection = localDirection.clone().applyQuaternion(group.quaternion)
    const pointsAndDistances: { point: FreePositions, distance: number, }[] = []

    pointsObjects.forEach(point => {
        const vectorToPoint = point.position.clone().sub(center)
        const crossProduct = worldDirection.clone().cross(vectorToPoint)

        if (crossProduct.lengthSq() < 1e-6) {
            const distanceAlongDirection = vectorToPoint.dot(worldDirection)
            pointsAndDistances.push({ point, distance: distanceAlongDirection, })
        }
    })

    pointsAndDistances.sort((a, b) => a.distance - b.distance)

    if (pointsAndDistances.length === 0) {
        return null
    }

    const sortedPoints = pointsAndDistances.map(item => item.point)
    return sortedPoints
}

function getClosestPointInDirectionMiddle(
    pointsObjects: FreePositions[],
    center: Vector3,
    localDirection: Vector3,
    startLength = 0,
    endLength = 0,
    group: Object3D
) {
    const sortedPoints = getClosestPointsInDirection(
        pointsObjects,
        center,
        localDirection,
        startLength,
        endLength,
        group
    )

    if (!sortedPoints) {
        return null
    }

    const lastPoint = sortedPoints[sortedPoints.length - 1]
    const closestPoint = sortedPoints[0]

    const addedLength = calculateAddedLength(
        localDirection,
        closestPoint,
        lastPoint,
        startLength,
        endLength,
    )

    const point = lastPoint.position.clone()
    const worldDirection = localDirection.clone().applyQuaternion(group.quaternion)
    const scaledLocalDirection = worldDirection.clone().multiplyScalar(addedLength)
    const adjustedPointPosition = point.clone().add(scaledLocalDirection)

    return adjustedPointPosition
}


function getClosestPointInDirectionEnds(
    pointsObjects: FreePositions[],
    center: Vector3,
    localDirection: Vector3,
    startLength = 0,
    endLength = 0,
    group: Object3D,
    addLength = false
) {
    const sortedPoints = getClosestPointsInDirection(
        pointsObjects,
        center,
        localDirection,
        startLength,
        endLength,
        group
    )
    if (!sortedPoints) {
        return null
    }
    if (!addLength) {
        const lastPoint = sortedPoints[sortedPoints.length - 1]
        return lastPoint.position
    }
    const lastPoint = sortedPoints[sortedPoints.length - 1]
    const closestPoint = sortedPoints[0]
    const addedLength = calculateAddedLength(
        localDirection,
        closestPoint,
        lastPoint,
        startLength,
        endLength,
    )
    const point = lastPoint.position.clone()
    const worldDirection = localDirection.clone().applyQuaternion(group.quaternion)
    const scaledLocalDirection = worldDirection.clone().multiplyScalar(addedLength)
    const adjustedPointPosition = point.clone().add(scaledLocalDirection)
    return adjustedPointPosition
}


function calculateAddedLength(
    localDirection: Vector3,
    closestPoint: FreePositions,
    lastPoint: FreePositions,
    startLength: number,
    endLength: number,
) {
    let addedLength = 0
    if (localDirection.x === 1 || localDirection.x === -1) {
        const firstIntersect = parseInt(closestPoint.meshName.split("_")[1], 10)
        const lastIntersect = parseInt(lastPoint.meshName.split("_")[1], 10)
        const isStart = firstIntersect > lastIntersect

        addedLength = isStart ? startLength : endLength

        // add one extra middle length, seems markers start at the first one?
        // addedLength += 0.125 // FIXME: this is a hack, but it works
        addedLength = convertInToCm(addedLength / 100)
    }
    return addedLength
}

export function getMeasurement(
    pointsObjects: FreePositions[],
    center: Vector3,
    startLength = 0,
    endLength = 0,
    group: Object3D,
    strategy: MeshStrategy,
    isMiddle: boolean,
) {
    const up = new Vector3(0, 1, 0)
    const down = new Vector3(0, -1, 0)
    const right = new Vector3(1, 0, 0)
    const left = new Vector3(-1, 0, 0)

    const directions = [up, down, right, left,]
    const directionNames = ["upPoint", "downPoint", "rightPoint", "leftPoint",]
    const points: { [key: string]: Vector3 | null, } = {}
    const getClosestPointInDirection = strategy === MeshStrategy.Markers
        ? getClosestPointInDirectionMiddle : getClosestPointInDirectionEnds

    //to account for small values that are meant to be basically 0
    //not to mess up measurement calculations

    let adjustedStartLength = startLength
    let adjustedEndLength = endLength

    if (adjustedEndLength <= 0.01) {
        adjustedEndLength = 0
    }
    if (adjustedStartLength <= 0.01) {
        adjustedStartLength = 0
    }

    for (let i = 0; i < directions.length; i++) {
        points[directionNames[i]] = getClosestPointInDirection(
            pointsObjects,
            center,
            directions[i],
            adjustedStartLength,
            adjustedEndLength,
            group,
            isMiddle
        )
    }

    const { upPoint, downPoint, rightPoint, leftPoint, } = points
    return {
        up: upPoint,
        down: downPoint,
        right: rightPoint,
        left: leftPoint,
    }
}

export function getDistances(position: Vector3, measurements: {
    up: Vector3 | null,
    down: Vector3 | null,
    right: Vector3 | null,
    left: Vector3 | null,
}) {

    const upDistance = measurements.up && position.distanceTo(measurements.up)
    const downDistance = measurements.down && position.distanceTo(measurements.down)
    const rightDistance = measurements.right && position.distanceTo(measurements.right)
    const leftDistance = measurements.left && position.distanceTo(measurements.left)

    return {
        up: upDistance,
        down: downDistance,
        right: rightDistance,
        left: leftDistance,
    }
}