/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-negated-condition */
/* eslint-disable max-statements */
/* eslint-disable default-case */
/* eslint-disable max-len */
import React, { useCallback, useContext } from "react"
import { useEffect, useRef, useState } from "react"
import { useThree } from "@react-three/fiber"
import { SegmentedTubeInfo, SectionType, SEGMENTED_TUBE_UI, SegmentedTubeMarkers } from "./types/types"
import SegmentedTubeUtils from "./utils/SegmentedTubeUtils"
import useInstancedMeshSegmentedTubes from "../../../../../../../providers/instancedMeshSegmentedTubesProvider/useInstancedMeshSegmentedTubes"
import { Box3, Box3Helper, BufferGeometry, Color, DoubleSide, Line, LineBasicMaterial, MathUtils, Matrix4, Mesh, MeshBasicMaterial, MeshStandardMaterial, Object3D, PlaneGeometry, Quaternion, Scene, Sphere, Vector3 } from "three"
import SegmentedTubeUI from "./ui/SegmentedTubeUI"
import useSegmentedTube from "./utils/useSegmentedTube"
import { isItemSelected } from "../../../../../../../state/atoms"
import { useRecoilState, useRecoilValue } from "recoil"
import { PartTypeEnum, XYZ, XYZW } from "../../../../../../../utils/Types"
import NewPartButtonsGroup from "./NewPartButtons/NewPartButtonsGroup"
import { allConnections, partConnections, undoRedoInProgressSelector, undoRedoChangeSelector } from "../../../../../../../state/scene/selectors"
import useGLTFLoader from "../../../../../../../providers/GLTFLoaderProvider/useGLTFLoader"
import { arrayRange, filterWithValue, SELECTED_PART_COLOR } from "../../../../../../../../common/utils/utils"
import { useRegisterSlide, useSlide } from "../../../../../../../providers/slideProvider/useSlide"
import { getMarkerNumber, innerToOuter } from "../../../../../../../utils/MarkerUtil"
import { MeshUtils } from "../../../../../../../utils/MeshUtils"
import { connectionTypesSelector } from "../../../../../../../state/initialDataSelectors"
import { useMultipleUpdates, useReplaceConnection, useUnsnap } from "../../../../../../../state/scene/setters"
import { useMultipleMovement, useRegisterMultipleMovement } from "../../../../../../../providers/multipleMovementProvider/useMultipleMovement"
import { ConnectionOfPart, PartConnectionType, UnitType } from "../../../../../../../state/scene/types"
import { MultipleMovementUtils } from "./utils/MultipleMovementUtils"
import { useHandleLengthChange } from "./utils/useHandleLengthChange"
import { SegmentedTubeSectionType } from "../../../../../../../../common/api/Types"
import { SoundHelper } from "../../../../utils/SoundHelper"
import { metersToInch } from "../../../../utils/utilsThree"
import useCamera from "../../../../../../../providers/cameraProvider/useCamera"
import { useRotationUtils } from "./utils/useRotationUtils"
import useCloseMarkers from "../../../../../../../providers/closeMarkersProvider/useCloseMarkers"
import useUnitConversion, { convertCmToIn, convertInToCm } from "../../../../utils/UnitUtils"
import { Billboard, Html, Text } from "@react-three/drei"
import { checkSnap } from "../tube/utils/TubeUtils"
import { useRegisterComponent, useComponentRegistry } from "../../../../../../../providers/multiselectProvider/useComponentMethods"
import { useNewPart } from "../../../../../../../state/scene/setters"
import { initialData } from "../../../../../../../state/atoms"
import { initialDataType } from "../../../../../../../state/types"
import { sceneAtom } from "../../../../../../../state/scene/atoms"
import { useLevaControls } from "../../../../../../../providers/debugProvider/useLevaControls"
import { MultiSelectContext } from "../../../../../../../providers/multiselectProvider/MultiSelectProvider"
import useMoonTexture from "../hooks/useMoonTexture"
import { calc } from "antd/es/theme/internal"
import useGetDebugVariables from "../../../../utils/useGetDebugVariables"
import { getPointsArrayForSingleEnd } from "../../../../../../../providers/moveProvider/meshBuilderEnds"
import hotkeys from "hotkeys-js"



interface Props {
    id: string;
    handleDeletePart: (id: string) => void;
}

const SegmentedTube = (props: Props) => {
    const [boundingBox, setBoundingBox,] = useState<Box3 | null>(null)
    const undoRedoInProgress = useRecoilValue(undoRedoInProgressSelector)
    const undoRedoChange = useRecoilValue(undoRedoChangeSelector)
    const { tube, updateTubeValues, } = useSegmentedTube(props.id)
    const [isSelected, setIsSelected,] = useRecoilState(isItemSelected(tube.id))
    const [tubeUI, setTubeUI,] = useState<SEGMENTED_TUBE_UI>(SEGMENTED_TUBE_UI.NONE)
    const { scene, } = useThree()
    const segmentedTubeRef = useRef<SegmentedTubeInfo>(SegmentedTubeUtils.initSegmentedTubeInfo(scene))
    const [, setAttachmentPoint,] = useState(new Object3D())
    const { loadModel, } = useGLTFLoader()
    const [hideAddPartButtons, setHideAddPartButtons,] = useState(false)
    const partConnectionsValue = useRecoilValue(partConnections(tube.id))
    const [maxPossibleLength, setMaxPossibleLength,] = useState(tube.maxMiddles)
    const minLengthRef = useRef(1)
    const auxPointRef = useRef(new Mesh())
    const connectionTypes = useRecoilValue(connectionTypesSelector)
    const connections = useRecoilValue(allConnections)
    const multipleUpdate = useMultipleUpdates()
    const unsnap = useUnsnap()
    const markerMeshesWithoutMiddleSegments = useRef<Object3D[]>([])
    const closestMarkersHook = useCloseMarkers(tube.id)
    const closestMarkers = useRef<Mesh[]>([])
    const instantiated = useRef(false)
    const [unit, updateUnit,] = useState(tube.partUnits)
    const slide = useSlide(tube.id)
    const [boundingBoxWithPadding, setBoundingBoxWithPadding,] = useState<Box3 | null>(null)
    const allParts = useRecoilValue(initialData)
    const { viewOuterMarkers, viewInnerMarkers, viewTubeNames, } = useLevaControls()
    const multiSelectContext = useContext(MultiSelectContext)
    const { unregister, } = useComponentRegistry()
    const [initialMarkerState, setInitialMarkerState,] = useState(tube.initialMarkerName)
    const [uiVisible, setUiVisible,] = useState(false)
    const middleConnectionPositionsRef = useRef<{ segmentStep: number, connection: ConnectionOfPart, }[]>([])
    const { moonTexture, } = useMoonTexture(8, 8)
    const replaceConnection = useReplaceConnection()
    const { getVariables, } = useGetDebugVariables()
    const isAdmin = getVariables().isAdmin
    const { hideMergedMeshes, } = useLevaControls()


    const wasDuplicated = !!tube.duplicatedFrom

    const findPartById = (apiTypeId: string) => {
        return allParts?.parts.find(part => part.id === apiTypeId)
    }

    const createPart = useNewPart()

    const {
        attachToMove,
        detachMarkers,
        handleSegmentedTubeLenghMovement,
        saveSegmentedTubeSliderChanges,
        canEditSegmentedTubeLength,
        handleSlideMovement,
        checkMaxLength,
        canEditSlide,
        saveRotationChanges,
    } = useMultipleMovement(tube.id)



    const [movableSection, setMovableSection,] = useState<Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>>(SegmentedTubeSectionType.END)

    const setUserRotationApplied = () => {
        updateTubeValues(tube.id, (t) => {
            t.userRotation = 0
        })
    }

    const [actualLength, setActualLength,] = useState({ positiveLength: tube.length, negativeLength: tube.lengthNegativeSide, })

    const middlePartInstancedMeshProvider = useInstancedMeshSegmentedTubes()
    const startPartInstancedMeshProvider = useInstancedMeshSegmentedTubes()
    const endPartInstancedMeshProvider = useInstancedMeshSegmentedTubes()
    const { getMiddleMesh, getStartMesh, getEndMesh, } = useInstancedMeshSegmentedTubes()
    const middleMesh = getMiddleMesh(tube.name)
    const startMesh = getStartMesh(tube.name)
    const endMesh = getEndMesh(tube.name)

    const updateTransforms = useCallback(() => {
        return
        SegmentedTubeUtils.updateInstancedTransforms(
            middlePartInstancedMeshProvider,
            startPartInstancedMeshProvider,
            endPartInstancedMeshProvider,
            segmentedTubeRef,
            tube.segmentScaleFactor,
            undefined,
            movableSection
        )
    }, [
        middlePartInstancedMeshProvider,
        startPartInstancedMeshProvider,
        endPartInstancedMeshProvider,
        segmentedTubeRef,
        tube.segmentScaleFactor,
        movableSection,
    ])

    const { fitBox, getInAutofocusMode, setInAutofocusMode, addMeshToSceneBounds, drawVector3Point, } = useCamera()

    useEffect(() => {
        if (boundingBox) {
            const growthFactor = 0.0005 // Adjust this value as needed
            const clonedBox = boundingBox.clone() // Clone the bounding box
            clonedBox.expandByScalar(growthFactor)
            setBoundingBoxWithPadding(clonedBox)
        }
    }, [boundingBox,])

    const { rotationSliderConfig, } = useRotationUtils({
        tubeId: props.id,
        tubeInfoRef: segmentedTubeRef,
        updateTransforms,
        setHideAddPartButtons,
        setUserRotationApplied,
        tubeUI,
        setTubeUI,
        movableSection,
        userRotation: tube.userRotation,
        unsnap: unsnap,
        boundingBox: boundingBoxWithPadding,
        initialMarkerName: initialMarkerState,
    })

    const boundgBoxForCamera = new Box3()

    const requestidleCallbackWithFallback = (callback: () => void, timeout: number) => {
        if (typeof requestIdleCallback === "undefined") {
            setTimeout(callback, timeout)
        } else {
            requestIdleCallback(callback)
        }
    }

    useEffect(() => {
        if (hideMergedMeshes && segmentedTubeRef.current.mergedMesh) {
            segmentedTubeRef.current.mergedMesh.visible = false
        } else if (!hideMergedMeshes && segmentedTubeRef.current.mergedMesh) {
            segmentedTubeRef.current.mergedMesh.visible = true
        }
    }, [hideMergedMeshes,])

    const calcBoundgBox = () => {
        if (segmentedTubeRef.current.mergedMesh) {
            boundgBoxForCamera.makeEmpty()
            segmentedTubeRef.current.mergedMesh.geometry.computeBoundingBox()
            boundgBoxForCamera.expandByObject(segmentedTubeRef.current.mergedMesh)
            // const clonedBox = boundgBoxForCamera.clone().applyMatrix4(segmentedTubeRef.current.mergedMesh.matrixWorld)
            setBoundingBox(boundgBoxForCamera)
            //const help = new Box3Helper(clonedBox, new Color("blue"))
            //scene.add(help)
            return boundgBoxForCamera
        }
        const origins = segmentedTubeRef.current.instancedMeshOrigins
        const firstObject = origins[0]
        const lastObject = origins[origins.length - 1]
        if (firstObject && lastObject) {
            const firstMesh = firstObject.children.find(child => child.name.includes("mesh")) || firstObject.children[1]
            const lastMesh = lastObject.children.find(child => child.name.includes("mesh")) || lastObject.children[1]

            if (firstMesh && lastMesh) {
                (firstMesh as Mesh).geometry.computeBoundingBox();
                (lastMesh as Mesh).geometry.computeBoundingBox()

                boundgBoxForCamera.makeEmpty()

                boundgBoxForCamera.expandByObject(firstMesh)
                boundgBoxForCamera.expandByObject(lastMesh)

                const clonedBox = boundgBoxForCamera.clone()
                setBoundingBox(clonedBox)

                // Uncoment to debug bounds
                //const help = new Box3Helper(clonedBox, new Color("blue"))
                //scene.add(help)
                return boundgBoxForCamera
            }
        }

    }


    const updateCamera = () => {
        if (segmentedTubeRef.current.mergedMesh) {
            if (getInAutofocusMode()) {

                segmentedTubeRef.current.mergedMesh.geometry.computeBoundingBox()
                if (segmentedTubeRef.current.mergedMesh.geometry.boundingBox) {
                    fitBox(
                        segmentedTubeRef.current.mergedMesh.geometry.boundingBox,
                        undefined
                    )
                }
            }
            setTimeout(() => {
                calcBoundgBox()
            }, 2000)
            return
        }
        const origins = segmentedTubeRef.current.instancedMeshOrigins
        const firstObject = origins[0]
        const lastObject = origins[origins.length - 1]
        if (firstObject && lastObject) {
            const firstMesh = firstObject.children[1]
            const lastMesh = lastObject.children[1]
            if (firstMesh && lastMesh) {

                calcBoundgBox()

                if (getInAutofocusMode()) {
                    fitBox(
                        boundgBoxForCamera,
                        undefined
                    )
                }
            }
        }
    }

    const handleLengthChanges = useHandleLengthChange({
        saveChanges: saveSegmentedTubeSliderChanges,
        tubeInternalRef: segmentedTubeRef,
        tubeUI: tubeUI,
        canEdit: canEditSegmentedTubeLength,
        direction: movableSection,
        checkMaxLength,
        updateMaxMinPossibleLength: (partsToMove?: string[]) => {
            updateMinLength()
            SegmentedTubeUtils.checkMaxPossibleLength(
                scene,
                tube,
                segmentedTubeRef,
                partConnectionsValue,
                connectionTypes,
                movableSection,
                setMaxPossibleLength,
                actualLength,
                partsToMove
            )
        },
    })
    const slideRegister = useRegisterSlide({
        id: props.id,
        sides: segmentedTubeRef.current.middleSection,
        startLength: tube.startSegmentLength,
        endLength: tube.endSegmentLength,
        segmentLength: tube.segmentLength,
    })

    const detachFromMarker = MultipleMovementUtils.useDetachFromMarker({ scene, })

    useRegisterMultipleMovement({
        id: props.id,

        attachToMarker: (marker: Mesh) => {
            const origin = segmentedTubeRef.current.attachmentPoint!
            marker.attach(origin)
        },

        detachFromMarker: (
            marker: Mesh,
            connections: PartConnectionType[],
            getExactSnaps?: boolean,
            checkCollisions?: boolean,
            resetGuidelines?: boolean
        ) => {
            return detachFromMarker(
                marker,
                tube,
                segmentedTubeRef,
                partConnectionsValue,
                connectionTypes,
                connections,
                movableSection,
                getExactSnaps,
                resetGuidelines)
        },
        checksOnLengthMove: (
            elongationDirection: Vector3,
            connections: PartConnectionType[],
            lengthDirection: number
        ) => {
            return {
                ...MultipleMovementUtils.checkMultipleMoveSnap(
                    scene,
                    tube,
                    segmentedTubeRef,
                    partConnectionsValue,
                    connectionTypes,
                    connections,
                    movableSection),
                ...{
                    drag: true,
                    dragDistance: lengthDirection < 0 ? -lengthDirection : lengthDirection,
                },
            }
        },
        checksOnSegmentedTubeLengthMove: (intersectableMeshes: Object3D[], connections: PartConnectionType[], checkSnap?: boolean) => {
            MultipleMovementUtils.checkMultipleMoveAligments(scene, tube, segmentedTubeRef, partConnectionsValue, [SegmentedTubeSectionType.START, SegmentedTubeSectionType.END,], intersectableMeshes)
            if (checkSnap) {
                const { newConnections, } = SegmentedTubeUtils.checkSnapOnEditionMovableSection(
                    scene,
                    tube,
                    segmentedTubeRef,
                    partConnectionsValue,
                    connectionTypes,
                    connections,
                    movableSection,
                    [],
                    [],
                    true
                )
                if (newConnections.length > 0) {
                    SoundHelper.playUnsnap()
                }
            }
        },
        checksOnRotationMove: (connections: PartConnectionType[], intersectableMeshes: Object3D[]) => {
            const { newConnections, } = SegmentedTubeUtils.checkSnapOnEditionMovableSection(
                scene,
                tube,
                segmentedTubeRef,
                partConnectionsValue,
                connectionTypes,
                connections,
                movableSection,
                [],
                [],
                true
            )
            MultipleMovementUtils.checkMultipleMoveAligments(scene, tube, segmentedTubeRef, partConnectionsValue, [movableSection,], intersectableMeshes)
            if (newConnections.length > 0) {
                SoundHelper.playUnsnap()
            }
        },
        getMaxLength: (direction: Vector3, connections?: PartConnectionType[]) => {
            if (MultipleMovementUtils.isMovableMarkerConnected(tube, segmentedTubeRef, connections!, movableSection)) {
                return null
            } else {
                return ((SegmentedTubeUtils.getMaxPossibleLength(
                    scene,
                    tube,
                    segmentedTubeRef,
                    partConnectionsValue,
                    connectionTypes,
                    movableSection,
                    actualLength
                ) - (actualLength.positiveLength + actualLength.negativeLength)) * tube.segmentLength) / metersToInch
            }
        },
        getPosAndRot: () => {
            const attachmentPoint = segmentedTubeRef.current.attachmentPoint
            const pos = MeshUtils.copyWorldPosition(attachmentPoint!)
            attachmentPoint!.rotateY(MathUtils.degToRad(-180))
            const rot = MeshUtils.copyWorldQuaternion(attachmentPoint!)
            attachmentPoint!.rotateY(MathUtils.degToRad(180))
            return { pos, rot, }
        },
        updateTransforms,
    })

    const onTubeSelected = () => {
        setInAutofocusMode(true)
        updateColor(SELECTED_PART_COLOR)
        setIsSelected(true)


        //when not following this flow, the box orientation is wrong
        const box = new Box3()
        box.makeEmpty()
        if (segmentedTubeRef.current.mergedMesh) {
            segmentedTubeRef.current.mergedMesh.geometry.computeBoundingBox()
            box.expandByObject(segmentedTubeRef.current.mergedMesh)
        }

        //unfortunately sometimes autofocus state is not updating fast enough sometimes
        if (segmentedTubeRef.current.mergedMesh) {
            segmentedTubeRef.current.mergedMesh.geometry.computeBoundingBox()
            if (segmentedTubeRef.current.mergedMesh.geometry.boundingBox) {
                fitBox(
                    box,
                    undefined
                )
            }
        }
        if (viewOuterMarkers || viewInnerMarkers) {
            // hack to allow marker selection on debug tool without activating other tube selection
            setTubeUI(SEGMENTED_TUBE_UI.SEGMENTED_SLIDER)
            return
        }
        multiSelectContext?.setTransformMode("off")
        //console.log(rotationSliderConfig.getRotationMarker(), "rotationSliderConfig.getRotationMarker()")
    }

    const getProvider = (sectionType: SectionType) => {
        switch (sectionType) {
            case SectionType.END:
                return endPartInstancedMeshProvider
            case SectionType.MIDDLE:
                return middlePartInstancedMeshProvider
            case SectionType.START:
                return startPartInstancedMeshProvider
        }
    }


    const loadModelsAndMarkers = async (modelPath: string) => {
        const gltf = await loadModel(modelPath)
        // This function will decompose the blender file into usable data
        segmentedTubeRef.current.blenderData = SegmentedTubeUtils.processData(gltf)
    }

    const createPools = () => {
        segmentedTubeRef.current.blenderData.forEach((bd) => {
            SegmentedTubeUtils.createPool(
                props.id,
                `${tube.name}_${bd.sectionType}`,
                bd.mainModel,
                scene,
                getProvider(bd.sectionType),
                onTubeSelected)
        })
    }

    const updateMarkersClosest = () => {
        const markers = [
            ...Object.values(segmentedTubeRef.current.startSection).map(marker => marker.outer),
            ...Object.values(segmentedTubeRef.current.endSection).map(marker => marker.outer),
            ...Object.values(segmentedTubeRef.current.middleSection).flatMap(side =>
                Object.values(side).map(marker => marker.outer)
            ),
        ]
        closestMarkersHook.updateMarkers(filterWithValue(markers))
    }

    const createPivotPoint = () => {
        // Create pivot point of the tube
        // Dummy objects of each part will be attached to this
        segmentedTubeRef.current.originPoint = new Object3D()
        segmentedTubeRef.current.originPoint.name = "Origin Point"
        // const axeHelper = new AxesHelper(1)
        // info.current.pivotPoint.add(axeHelper);
        // info.current.pivotPoint.position.set(props.position[0], props.position[1], props.position[2]);
        // SegmentedTubeInfo.current.tubePivotPoint.rotation.set(MathUtils.radToDeg(45), MathUtils.radToDeg(45), 0)
        scene.add(segmentedTubeRef.current.originPoint)
    }

    const updateSlide = (tubeRef: SegmentedTubeInfo) => {
        const middleSection = tubeRef.middleSection
        const filterSectionByMeshProperty = (section: SegmentedTubeMarkers) => {
            return Object.entries(section)
                .filter(([_, value,]) => "mesh" in value)
                .reduce((acc, [key, value,]) => ({ ...acc, [key]: value, }), {})
        }
        const encloseSectionObjects = (section: SegmentedTubeMarkers) => {
            return Object.entries(section)
                .reduce((acc, [key, value,]) => ({ ...acc, [key]: { "0": value, }, }), {})
        }

        const startSection = encloseSectionObjects(filterSectionByMeshProperty(tubeRef.startSection))
        const endSection = encloseSectionObjects(filterSectionByMeshProperty(tubeRef.endSection))

        const sides = {
            ...startSection,
            ...middleSection,
            ...endSection,
        }

        slideRegister.updateSlide(sides)
    }

    const setupAttachmentPoint = () => {
        const attachmentPoint = getAttachmentPointOrigin()

        const secondaryAttachmentPoint = segmentedTubeRef.current.instancedMeshOrigins[2]
            ? (segmentedTubeRef.current.instancedMeshOrigins[2].children.length >= 3
                ? segmentedTubeRef.current.instancedMeshOrigins[2].children[2]
                : segmentedTubeRef.current.instancedMeshOrigins[2].children[0])
            : undefined

        //console.log(secondaryAttachmentPoint, "secondaryAttachmentPoint")

        let markerPosition
        if (tube.markerOffset) {
            markerPosition = tube.markerOffset
        } else {
            markerPosition = tube.position
        }
        SegmentedTubeUtils.setAtachmentPoint(
            attachmentPoint || secondaryAttachmentPoint!, segmentedTubeRef, setAttachmentPoint
        )
        //console.log(markerPosition, "markerPosition")
        //console.log(tube.rotation, "tube.rotation")
        setPositionAndRotation(markerPosition, tube.rotation)
        //console.log(tube.markerOffset, "tube.markerOffset")

        if (tube.markerOffset) {
            const apPosition = new Vector3(tube.position.x, tube.position.y, tube.position.z)
            SegmentedTubeUtils.setAtachmentPoint(
                attachmentPoint || secondaryAttachmentPoint!, segmentedTubeRef, setAttachmentPoint, apPosition
            )
        }

    }

    const setupUnits = () => {
        //migration for bad negative length values
        let negativeLengthTouse = tube.lengthNegativeSide

        if (tube.lengthNegativeSide < 0) {
            negativeLengthTouse = 0
        }
        if (tube.unitRealValue || tube.loaded) {
            SegmentedTubeUtils.createInstancedMeshOrigins(
                tube,
                segmentedTubeRef,
                middlePartInstancedMeshProvider,
                startPartInstancedMeshProvider,
                endPartInstancedMeshProvider,
                tube.length,
                negativeLengthTouse,
                tube.segmentScaleFactor,
                moonTexture,
                getColor(),
                isAdmin ?? undefined,
            )
            return
        }
        const isInches = tube.name.includes("'") || /\b(in|In|IN)\b/.test(tube.name)
        const isMetric = /\b(mm|cm|MM|CM)\b/.test(tube.name) || tube.name.includes("mm") || tube.name.includes("cm")

        if (isInches) {
            SegmentedTubeUtils.createInstancedMeshOrigins(
                tube,
                segmentedTubeRef,
                middlePartInstancedMeshProvider,
                startPartInstancedMeshProvider,
                endPartInstancedMeshProvider,
                tube.length,
                negativeLengthTouse,
                tube.segmentScaleFactor,
                moonTexture,
                getColor(),
            )
            updateUnit("in")
        } else if (isMetric) {
            updateUnit("cm")
            const currentInLength = ((tube.length + negativeLengthTouse) * tube.segmentLength + tube.endSegmentLength + tube.startSegmentLength)
            const lengthInCm = convertInToCm(currentInLength)
            saveLength(lengthInCm, true, true)
        }
    }


    const getMiddleMarker = (inner: boolean, outer: boolean) => {
        const markers = Object.values(segmentedTubeRef.current.middleSection)
            .flatMap(side =>
                Object.values(side)
                    .filter(marker => (inner && "inner" in marker) || (outer && "outer" in marker))
                    .map(marker => {
                        const result = []
                        if (inner && "inner" in marker) {
                            result.push(marker.inner)
                        }
                        if (outer && "outer" in marker) {
                            result.push(marker.outer)
                        }
                        return result
                    })
            )
            .flat() // Flatten in case both inner and outer are selected

        //console.log(markers, "getMiddleMarker");
        return markers
    }


    const findMissingMarkersFromPartConnections = (partConnectionsValue: ConnectionOfPart[]) => {
        const allMarkers = getMiddleMarker(true, true)
        const missingMarkers: string[] = []

        //console.log(connections, "connections first")

        // Find missing markers
        partConnectionsValue.forEach(connection => {
            if (!allMarkers.some(marker => marker?.name === connection.partMarkerName) && connection.partMarkerName.includes("_")) {
                missingMarkers.push(connection.partMarkerName)
            }
        })

        if (missingMarkers.length === 0) { return }

        const newConnections: PartConnectionType[] = []

        //console.log(missingMarkers, "missingMarkers")

        //console.log(allMarkers, "allMarkers")

        missingMarkers.forEach(missingMarker => {
            if (missingMarker.includes("_")) {
                const [baseMarker,] = missingMarker.split("_")
                const isOuter = missingMarker.startsWith("outer")
                const middleMarkers = getMiddleMarker(true, true)

                const replacementMarker = middleMarkers.find(marker => {
                    const markerName = marker?.name
                    return markerName?.startsWith(baseMarker)
                        && ((isOuter && markerName?.startsWith("outer"))
                            || (!isOuter && markerName?.startsWith("inner")))
                })

                //console.log(replacementMarker, "replacementMarker")

                if (replacementMarker) {
                    // Find the existing connection
                    const existingConnection = partConnectionsValue.find(
                        conn => conn.partMarkerName === missingMarker
                    )
                    if (existingConnection) {
                        newConnections.push({
                            partA: {
                                partId: existingConnection.destinationPartId,
                                markerName: existingConnection.destinationMarkerName,
                            },
                            partB: {
                                partId: tube.id,
                                markerName: replacementMarker.name,
                            },
                        })
                        //console.log(existingConnection.slidePosition, "existingConnection.slidePosition")
                        replaceConnection(
                            tube.id,
                            existingConnection.partMarkerName,
                            {
                                partA: {
                                    partId: existingConnection.destinationPartId,
                                    markerName: existingConnection.destinationMarkerName,
                                },
                                partB: {
                                    partId: tube.id,
                                    markerName: replacementMarker.name,
                                },
                            },
                            existingConnection.slidePosition!,
                            undefined,
                            true
                        )

                    }
                }
                // Check if the initialMarkerName needs to be updated
                if (tube.initialMarkerName === missingMarker && replacementMarker) {
                    updateTubeValues(tube.id, (t) => {
                        t.initialMarkerName = replacementMarker?.name
                    })
                    setInitialMarkerState(replacementMarker?.name)
                    //console.log(`Updated initialMarkerName from ${missingMarker} to ${replacementMarker.name}`)
                }
            }
        })

        console.log(connections, "all connections")

        console.log(newConnections, "newCOnnections")

        if (newConnections.length > 0) {
            //multipleUpdate([], newConnections)
        }
    }


    const initialSetup = async () => {
        console.log("initialSetup")

        if (tube.lengthNegativeSide < 0) {
            console.log("migrated bad negative length side value", tube.lengthNegativeSide, tube.length)
            updateTubeValues(tube.id, (t) => {
                t.lengthNegativeSide = 0
            })
        }

        // Create pivot point
        createPivotPoint()
        // Load models to segmentedTubeInfo
        await loadModelsAndMarkers(`${tube.fileURL}?t=${Date.now()}`)
        // Create instanced meshes pools
        //createPools()
        // Create Origins

        setTimeout(() => {
            SegmentedTubeUtils.createInstancedMeshOrigins(
                tube,
                segmentedTubeRef,
                middlePartInstancedMeshProvider,
                startPartInstancedMeshProvider,
                endPartInstancedMeshProvider,
                tube.length,
                0,
                tube.segmentScaleFactor,
                moonTexture,
                getColor(),
                isAdmin ?? undefined,
            )

            setupAttachmentPoint()

            SegmentedTubeUtils.checkMaxPossibleLength(scene, tube, segmentedTubeRef, partConnectionsValue, connectionTypes, movableSection, setMaxPossibleLength, actualLength)
            SegmentedTubeUtils.setSnappedStateOnLoad(segmentedTubeRef, partConnectionsValue)
            const defaultMovementSide = segmentedTubeRef.current.snapped.end ? SegmentedTubeSectionType.START : SegmentedTubeSectionType.END
            setDirection(defaultMovementSide)
            if (tube.loaded) {
                setTubeUI(SEGMENTED_TUBE_UI.NONE)
                updateColor(getColor())
            }
            if (!tube.loaded && !wasDuplicated) {
                onTubeSelected()
            }
            setupUnits()

            updateSlide(segmentedTubeRef.current)
            updateMinLength()
            SegmentedTubeUtils.checkDiagonalEdges(segmentedTubeRef)
            updateMarkersClosest()
            instantiated.current = true

            addMeshToSceneBounds()
            registerMultiSelectMethods()
            setPositionAndRotation(tube.position, tube.rotation)
            requestidleCallbackWithFallback(() => {
                addLocalSlidePointsForAllMarkers()
            }, 300)
        }, 50)
    }


    const getEveryMarker = () => {
        const markers = [
            ...Object.values(segmentedTubeRef.current.startSection).flatMap(markerGroup =>
                Object.values(markerGroup).filter(marker => marker !== undefined)
            ),
            ...Object.values(segmentedTubeRef.current.endSection).flatMap(markerGroup =>
                Object.values(markerGroup).filter(marker => marker !== undefined)
            ),
            ...Object.values(segmentedTubeRef.current.middleSection).flatMap(side =>
                Object.values(side).flatMap(markerGroup =>
                    Object.values(markerGroup).filter(marker => marker !== undefined)
                )
            ),
        ]
        return markers
    }

    const addLocalSlidePointsForAllMarkers = () => {
        const markers = getEveryMarker()
        markers.forEach(marker => {
            if (marker && marker.name?.includes("mesh")) {
                const { freePositions, } = getPointsArrayForSingleEnd(marker, 0.25)
                const localSlidePoints = freePositions.map(point => {
                    const localPoint = new Vector3()
                    const positionCopy = new Vector3().copy(point.position)
                    marker.worldToLocal(positionCopy)
                    return { position: positionCopy, }
                })
                marker.userData.localSlidePoints = localSlidePoints
            }
        })

        markers.forEach(marker => {
            if (marker && marker.name?.includes("inner")) {
                const meshName = marker.name.replace(/^(inner|outer)/, "mesh")
                const correspondingMesh = markers.find(m => m?.name === meshName)
                //console.log("makername", marker.name, correspondingMesh, "correspondingMesh")

                if (correspondingMesh && correspondingMesh.userData.localSlidePoints) {
                    marker.userData.localSlidePoints = correspondingMesh.userData.localSlidePoints
                }
            }
        })
        //console.log(markers, "markers after adding local slide points")
    }

    useEffect(() => {
        requestidleCallbackWithFallback(() => {
            addLocalSlidePointsForAllMarkers()
        }, 300)
    }, [tube.length, tube.lengthNegativeSide,])


    const setPositionAndRotation = (position: XYZ, rotation: XYZW) => {
        segmentedTubeRef.current.attachmentPoint?.position.copy(new Vector3(position.x, position.y, position.z))
        segmentedTubeRef.current.attachmentPoint?.quaternion.copy(new Quaternion(rotation.x, rotation.y, rotation.z, rotation.w))
        segmentedTubeRef.current.attachmentPoint?.rotateY(MathUtils.degToRad(180))
        updateTransforms()
    }

    useEffect(() => {
        initialSetup()


        return () => {
            deletePart()
        }
    }, [])

    const deletePart = () => {
        SegmentedTubeUtils.cleanUpPreviousOrigins(segmentedTubeRef)
        SegmentedTubeUtils.removeGuidelines(scene, segmentedTubeRef)
        if (segmentedTubeRef.current.mergedMesh) {
            segmentedTubeRef.current.mergedMesh.removeFromParent()
        }
        //middlePartInstancedMeshProvider.deleteInstancedMesh()
        //startPartInstancedMeshProvider.deleteInstancedMesh()
        //endPartInstancedMeshProvider.deleteInstancedMesh()
    }

    const debugMiddleReferencePoints = (
        info: SegmentedTubeInfo,
        scene: Scene,
        color = "red",
        size = 0.0005
    ) => {
        const middleSection = info.middleSection

        Object.values(middleSection).forEach(sideMarkers => {
            Object.values(sideMarkers).forEach(markers => {
                const { inner, outer, } = markers

                console.log(inner, "inner")
                console.log(outer, "outer")

                if (inner && inner.userData.middleRefs) {
                    inner.userData.middleRefs.forEach((ref: { refName: string, localOffset: Vector3, }) => {
                        const worldPosition = inner.localToWorld(new Vector3().copy(ref.localOffset))
                        drawVector3Point(worldPosition, scene, color, size, 5000, true)
                        //console.log(`Inner middleRef point drawn: ${ref.refName}, ${worldPosition.toArray().join(', ')}`)
                    })
                }

                if (outer && outer.userData.middleRefs) {
                    outer.userData.middleRefs.forEach((ref: { refName: string, localOffset: Vector3, }) => {
                        const worldPosition = outer.localToWorld(new Vector3().copy(ref.localOffset))
                        drawVector3Point(worldPosition, scene, "blue", size, 5000, true) // Using a different color for outer points
                        //console.log(`Outer middleRef point drawn: ${ref.refName}, ${worldPosition.toArray().join(', ')}`)
                    })
                }
                if (inner && inner.userData.localSlidePoints) {
                    inner.userData.localSlidePoints.forEach((ref: { meshName: string, position: Vector3, }) => {
                        const worldPosition = inner.localToWorld(new Vector3().copy(ref.position))
                        drawVector3Point(worldPosition, scene, "purple", size, 5000, true) // Using a different color for outer points
                        //console.log(`Outer middleRef point drawn: ${ref.refName}, ${worldPosition.toArray().join(', ')}`)
                    })
                }
            })
        })

        //console.log('Middle reference points visualized')
    }

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            event.preventDefault()
            removePart()
        }

        if (isSelected) {
            hotkeys("delete,backspace", handleKeyPress)
        }

        // Cleanup function
        return () => {
            hotkeys.unbind("delete,backspace", handleKeyPress)
        }
    }, [isSelected,])

    useEffect(() => {
        if (instantiated.current) {
            if (!isSelected) {
                setTubeUI(SEGMENTED_TUBE_UI.CLOSE)
                SegmentedTubeUtils.removeGuidelines(scene, segmentedTubeRef)
                updateColor(getColor())
            }

            if (isSelected) {
                //console.log("segmentedTubeRef current", segmentedTubeRef.current)
                //SegmentedTubeUtils.setSnappedStateOnLoad(segmentedTubeRef, partConnectionsValue)
                //console.log(tube.id, tube, "tube.id is selected", segmentedTubeRef.current)
                updateColor(getColor())
                onTubeSelected()
                //debugMiddleReferencePoints(segmentedTubeRef.current, scene)
                //console.log(connections, "connections")

            }
        }

        requestidleCallbackWithFallback(calcBoundgBox, 200)
    }, [isSelected,])

    const getAttachmentPointOrigin = () => {
        const outerInitialMarker = innerToOuter(tube.initialMarkerName)

        //console.log(outerInitialMarker, "outerInitialMarker")

        //console.log(getAttachmentPointMarker(outerInitialMarker), "getAttachmentPointOrigin")
        //console.log(segmentedTubeRef.current.startSection, "segmentedTubeRef.current.startSection")
        //console.log(segmentedTubeRef.current.endSection, "segmentedTubeRef.current.endSection")
        //console.log(segmentedTubeRef.current.middleSection, "segmentedTubeRef.current.middleSection")

        //console.log(getAllMarkers(), "getAllMarkers")

        return getAttachmentPointMarker(outerInitialMarker)
    }

    const getAttachmentPointMarker = (markerName: string) => {
        //console.log(markerName, "getAttachmentPointMarker markerName")

        let makerNametoFind = markerName

        const markerNameHasUnderScore = markerName.includes("_")
        const markerDoesHasUnderScoreZero = markerName.includes("_0")

        const markerConditions = markerNameHasUnderScore && !markerDoesHasUnderScoreZero

        if (markerConditions) {
            const [baseMarker, markerNumber,] = markerName.split("_")
            makerNametoFind = `${baseMarker}_0`
            //console.log(makerNametoFind, "makerNametoFind")
        }

        const originWithMarker = segmentedTubeRef.current.instancedMeshOrigins
            .find(origin => origin.children.some(c => c.name === makerNametoFind))

        //console.log(originWithMarker, "originWithMarker")

        if (originWithMarker && !markerConditions) {
            //console.log("doesn't meet")
            const childWithName = originWithMarker.children.find(child => child.name === markerName)
            //console.log(childWithName, "childWithName does not markerNameHasUnderScore")
            return childWithName
        }

        if (originWithMarker && markerConditions) {
            //console.log("meets")
            const childWithName = originWithMarker.children.find(child => child.name === makerNametoFind)
            //console.log(childWithName, "childWithName")

            if (childWithName && childWithName.userData.middleRefs) {
                const matchingRef = childWithName.userData.middleRefs.find(
                    (ref: { refName: string, }) => ref.refName === markerName
                )

                if (matchingRef) {
                    const clonedObject = new Object3D()
                    clonedObject.name = markerName
                    clonedObject.userData = { ...childWithName.userData, middleRefs: [matchingRef,], }

                    // Clone the position, rotation, and scale
                    clonedObject.position.copy(childWithName.position)
                    clonedObject.rotation.copy(childWithName.rotation)
                    clonedObject.scale.copy(childWithName.scale)

                    // Apply the localOffset to set the new position
                    const worldPosition = childWithName.localToWorld(new Vector3().copy(matchingRef.localOffset))
                    clonedObject.position.copy(worldPosition)

                    const worldQuaternion = new Quaternion()
                    childWithName.getWorldQuaternion(worldQuaternion)
                    clonedObject.quaternion.copy(worldQuaternion)

                    //drawVector3Point(worldPosition, scene, "green", 0.002, undefined, true)
                    //console.log(clonedObject, "clonedObject with adjusted position")

                    segmentedTubeRef.current.originPoint?.attach(clonedObject)
                    return clonedObject
                }
            }

            return childWithName
        }

        //console.log(undefined, `getAttachmentPointMarker ${markerName} undefined`)
        return undefined
    }

    const handleNewAttachmentPoint = (markerName: string, sliderPartId: string | undefined, position?: Vector3) => {
        const attachmentPoint = getAttachmentPointMarker(markerName)
        if (attachmentPoint) {
            const newAttachmentPoint = segmentedTubeRef.current.instancedMeshOrigins
                .find(origin => origin.children.some(c => c.name === markerName))
                ?.children.find(child => child.name === markerName)!

            if (newAttachmentPoint && sliderPartId) {
                newAttachmentPoint.rotateY(MathUtils.degToRad(-180))
                let newPos: Vector3
                if (position) {
                    newPos = position
                } else {
                    newPos = MeshUtils.copyWorldPosition(newAttachmentPoint)
                }
                const markerPosition = MeshUtils.copyWorldPosition(newAttachmentPoint)
                const newRot = MeshUtils.copyWorldQuaternion(newAttachmentPoint)
                newAttachmentPoint.rotateY(MathUtils.degToRad(180))

                SegmentedTubeUtils.setAtachmentPoint(newAttachmentPoint, segmentedTubeRef, setAttachmentPoint, newPos)
                slide.setNewAttachmentPoint(sliderPartId, markerName, newAttachmentPoint, newPos, drawVector3Point, scene)

                updateTubeValues(tube.id, (t) => {
                    t.initialMarkerName = newAttachmentPoint.name
                    t.position = { x: newPos.x, y: newPos.y, z: newPos.z, }
                    t.rotation = { x: newRot.x, y: newRot.y, z: newRot.z, w: newRot.w, }
                    t.markerOffset = { x: markerPosition.x, y: markerPosition.y, z: markerPosition.z, }
                })
            }

        }
    }
    const handleUpdateAttachmentPoint = () => {
        const attachmentPoint = getAttachmentPointOrigin()
        if (!attachmentPoint && segmentedTubeRef.current.instancedMeshOrigins.length > 0) {
            const markerName = innerToOuter(innerToOuter(tube.markers[tube.markers.length - 1].name))

            const newAttachmentPoint = segmentedTubeRef.current.instancedMeshOrigins
                .find(origin => origin.children.some(c => c.name === markerName))
                ?.children.find(child => child.name === markerName)!
            if (newAttachmentPoint) {
                newAttachmentPoint.rotateY(MathUtils.degToRad(-180))
                const newPos = MeshUtils.copyWorldPosition(newAttachmentPoint)
                const newRot = MeshUtils.copyWorldQuaternion(newAttachmentPoint)
                newAttachmentPoint.rotateY(MathUtils.degToRad(180))

                SegmentedTubeUtils.setAtachmentPoint(newAttachmentPoint, segmentedTubeRef, setAttachmentPoint)

                updateTubeValues(tube.id, (t) => {
                    t.initialMarkerName = newAttachmentPoint.name
                    t.position = { x: newPos.x, y: newPos.y, z: newPos.z, }
                    t.rotation = { x: newRot.x, y: newRot.y, z: newRot.z, w: newRot.w, }
                })
            }
        }
    }

    const removePart = () => {
        if (handleLengthChanges.multipleMove
            && handleLengthChanges.multipleMove?.partsToMove
        ) {
            attachToMove(handleLengthChanges.multipleMove.partsToMove, auxPointRef.current)
            detachMarkers(
                handleLengthChanges.multipleMove.partsToMove,
                auxPointRef.current,
                false,
                false,
                true
            )
        }
        if (segmentedTubeRef.current.mergedMesh) {
            segmentedTubeRef.current.mergedMesh.removeFromParent()
        }
        props.handleDeletePart(tube.id)
        unregister(tube.id)
    }

    const getMaxAndMinMiddlePositions = () => {
        const middleSection = segmentedTubeRef.current.middleSection
        const side = middleSection[Object.keys(middleSection)[0]]
        if (side) {
            const positions = Object.keys(side)
                .map(key => {
                    const inner = side[key].inner
                    if (inner && inner.name) {
                        const parts = inner.name.split("_")
                        return parts.length > 1 ? Number(parts[1]) : null
                    }
                    return null
                })
                .filter((position): position is number => position !== null)

            if (positions.length > 0) {
                positions.sort((a, b) => a - b)
                const min = positions[0]
                const max = positions[positions.length - 1]
                return { min, max, }
            }
        }
        return { min: 1, max: 1, }
    }
    const updateInitialMarkerPositionIfMiddle = (debug = false) => {

        if (debug) {
            const currentPosition = new Vector3(tube.position.x, tube.position.y, tube.position.z,)
            drawVector3Point(currentPosition, scene, "red", 0.002, 10000, true)
        }
        // 1. Check if initial marker name has underscore
        if (tube.initialMarkerName.includes("_")) {
            // 2. Get the new world position of that marker
            const allMarkers = getAllMarkers()
            const initialMarker = allMarkers.find(marker => marker?.name === innerToOuter(tube.initialMarkerName))

            if (initialMarker) {
                // 3. Get the world position
                const worldPosition = new Vector3()
                initialMarker.getWorldPosition(worldPosition)

                // 4. Update the position value of the tube
                updateTubeValues(tube.id, (t) => {
                    t.position = { x: worldPosition.x, y: worldPosition.y, z: worldPosition.z, }
                })

                // 5. Check if there is a markerOffset value and update it
                if (tube.markerOffset) {
                    updateTubeValues(tube.id, (t) => {
                        t.markerOffset = { x: worldPosition.x, y: worldPosition.y, z: worldPosition.z, }
                    })
                }

                if (debug) {
                    drawVector3Point(worldPosition, scene, "blue", 0.002, 10000, true)
                }



            } else {
                console.warn(`Initial marker ${tube.initialMarkerName} not found`)
            }
        }
    }

    const updateMinLength = useCallback(() => {
        const { min, max, } = getMaxAndMinMiddlePositions()

        middleConnectionPositionsRef.current = partConnectionsValue
            .map(connection => {
                const markerNumber = getMarkerNumber(connection.partMarkerName)
                // Check if markerNumber is defined and contains an underscore
                if (markerNumber && markerNumber.includes("_")) {
                    const [, segmentStep,] = markerNumber.split("_")
                    if (segmentStep) {
                        return {
                            segmentStep: Number(segmentStep),
                            connection: connection,
                        }
                    }
                }
                return null
            })
            .filter((item): item is { segmentStep: number, connection: ConnectionOfPart, } => item !== null)

        if (movableSection === SegmentedTubeSectionType.END) {
            //middleConnectionPositionsRef.current.sort((a, b) => b.segmentStep - a.segmentStep)
            //const minLength = middleConnectionPositionsRef.current[0].segmentStep - min + 1
            //console.log(minLength, "minLength in handleUpdateMinLength")
            //console.log(actualLength.negativeLength, "actualLength.negativeLength")

            //this is because we always need at least 1 segment on the positive sid
            let value = actualLength.negativeLength + 1
            if (value <= 0) {
                value = 1
            }
            //console.log(value, "value", movableSection, actualLength)
            minLengthRef.current = value
        } else {
            //middleConnectionPositionsRef.current.sort((a, b) => a.segmentStep - b.segmentStep)
            //const minLength = max - middleConnectionPositionsRef.current[0].segmentStep + 1
            //console.log(minLength, "minLength in else handleUpdateMinLength")
            let value = actualLength.positiveLength
            if (value <= 0) {
                value = 1
            }
            //console.log(value, "value", movableSection, actualLength)
            minLengthRef.current = value
            //console.log(actualLength.positiveLength, "actualLength.positiveLength")
        }
    }, [actualLength.negativeLength, actualLength.positiveLength,])

    useEffect(() => {
        updateMinLength()

        //console.log(middleConnectionPositionsRef.current, "middleConnectionPositionsRef.current")

        //visualize the connections
        //SegmentedTubeUtils.visualizeConnections(connections, 0x000000, 0x800080, scene)

        //console.log(connections, "connections", tube.id)

        segmentedTubeRef.current.snapped.end = SegmentedTubeUtils.isSomeMovableMarkerConnected(
            segmentedTubeRef,
            partConnectionsValue,
            SegmentedTubeSectionType.END
        )
        segmentedTubeRef.current.snapped.start = SegmentedTubeUtils.isSomeMovableMarkerConnected(
            segmentedTubeRef,
            partConnectionsValue,
            SegmentedTubeSectionType.END
        )
    }, [partConnectionsValue, actualLength.negativeLength, actualLength.positiveLength,])

    const handleMultipleMoveChangeLength = (value: number, isSameValue: boolean) => {
        if (!isSameValue) {
            SegmentedTubeUtils.checkSnap(scene, tube, segmentedTubeRef, partConnectionsValue, connections, connectionTypes, value, movableSection, maxPossibleLength)
            updateSlide(segmentedTubeRef.current)
            handleUpdateAttachmentPoint()
        }

        const markersByDirection = movableSection === SegmentedTubeSectionType.START ? segmentedTubeRef.current.startSection : segmentedTubeRef.current.endSection

        const marker = markersByDirection[getMarkerNumber(handleLengthChanges.multipleMove!.sliderMarker)].inner!
        const newWorldPosition = MeshUtils.copyWorldPosition(marker)

        auxPointRef.current.position.copy(newWorldPosition)
        updateTransforms()

        if (handleLengthChanges.multipleMove) {
            handleSegmentedTubeLenghMovement(
                handleLengthChanges.multipleMove!.partsToMove,
                markerMeshesWithoutMiddleSegments.current
            )
        }
    }

    const handleSliderValueChange = (value: number) => {

        let convertedValue = value

        if (unit === "cm") {
            convertedValue = convertCmToIn(value)
        }

        //console.log(convertedValue, "convertedValue")

        const notRoundedSegmentsCount = (convertedValue - tube.startSegmentLength - tube.endSegmentLength) / tube.segmentLength
        //console.log(notRoundedSegmentsCount, "notRoundedSegmentsCount")

        // Round to the nearest whole number of segments
        const roundedSegmentsCount = Math.round(notRoundedSegmentsCount)

        //console.log("minLength", minLength)
        //console.log("roundedSegmentsCount", roundedSegmentsCount)
        //console.log("connection", middleConnectionPositionsRef.current)
        //console.log(actualLength, "actualLength")


        // Calculate the adjusted segment length
        const adjustedSegmentLength = (convertedValue - tube.startSegmentLength - tube.endSegmentLength) / roundedSegmentsCount

        // Calculate the scale factor
        const scaleFactor = adjustedSegmentLength / tube.segmentLength

        //console.log(roundedSegmentsCount, "roundedSegmentsCount")
        //console.log(adjustedSegmentLength, "adjustedSegmentLength")
        //console.log(scaleFactor, "scaleFactor")

        // Verify our calculations
        const verifiedLength = (roundedSegmentsCount * adjustedSegmentLength) + tube.startSegmentLength + tube.endSegmentLength
        //console.log(verifiedLength, "IN: verifiedLength (should be very close to the original value)")
        //console.log(convertInToCm(verifiedLength), "CM: verifiedLength (should be very close to the original value)")

        let segmentedCount = Math.round((convertedValue - tube.startSegmentLength - tube.endSegmentLength) / adjustedSegmentLength)
        //console.log(segmentedCount, "segmentedCount")

        if (segmentedCount < 1) {
            segmentedCount = 1
        }

        const normalizedConnections = middleConnectionPositionsRef.current.map(item => {
            let normalizedStep
            if (movableSection === SegmentedTubeSectionType.START) {
                normalizedStep = item.segmentStep < 0
                    ? actualLength.positiveLength + Math.abs(item.segmentStep)
                    : actualLength.positiveLength - item.segmentStep
            } else {
                // For END section, keep the original logic
                normalizedStep = item.segmentStep < 0
                    ? Math.abs(item.segmentStep)
                    : item.segmentStep + actualLength.negativeLength
            }
            return {
                originalSegmentStep: item.segmentStep,
                normalizedStep,
                connection: item.connection,
            }
        })
        //console.log("Normalized Connections:", normalizedConnections)

        /*if (segmentedCount <= minLengthRef.current) {

            const connectionsToUnsnap = normalizedConnections.filter(item => {
                return item.normalizedStep >= segmentedCount
            })
            //console.log(connectionsToUnsnap, "connectionsToUnsnap")

            if (connectionsToUnsnap.length > 0) {
                // Unsnap all connections
                const markerNamesToUnsnap = connectionsToUnsnap.map(conn => conn.connection.partMarkerName)
                unsnap(tube.id, markerNamesToUnsnap)
                SoundHelper.playUnsnap()
            }
        }*/

        //console.log(segmentedCount, "segmentedCount")

        //end of new logic

        //const newLength = Math.round((value - tube.startSegmentLength - tube.endSegmentLength) / tube.segmentLength)

        let positiveLength = segmentedCount - actualLength.negativeLength
        let negativeLength = actualLength.negativeLength

        //console.log(negativeLength, "negativeLength")
        //console.log(positiveLength, "positiveLength")

        if (movableSection === SegmentedTubeSectionType.START) {
            positiveLength = actualLength.positiveLength
            negativeLength = segmentedCount - actualLength.positiveLength
        }

        updateTubeValues(tube.id, (t) => {
            t.length = positiveLength
            t.lengthNegativeSide = negativeLength
            t.scaledSegmentLength = adjustedSegmentLength
            t.segmentScaleFactor = scaleFactor
            t.unitRealValue = value
            t.partUnits = unit ?? "in"
        })

        const isSameValue = actualLength.positiveLength === positiveLength
            && actualLength.negativeLength === negativeLength

        SegmentedTubeUtils.createInstancedMeshOrigins(
            tube,
            segmentedTubeRef,
            middlePartInstancedMeshProvider,
            startPartInstancedMeshProvider,
            endPartInstancedMeshProvider,
            positiveLength,
            negativeLength,
            scaleFactor,
            moonTexture,
            getColor(),
            isAdmin ?? undefined,
        )



        //put after since markers for new buttons listen for this state

        setActualLength({ positiveLength, negativeLength, })

        if (handleLengthChanges.multipleMove?.partsToMove
            && handleLengthChanges.multipleMove?.partsToMove.length > 0
        ) {
            handleMultipleMoveChangeLength(value, isSameValue)
        } else {
            SegmentedTubeUtils.checkSnap(scene, tube, segmentedTubeRef, partConnectionsValue, connections, connectionTypes, value, movableSection, maxPossibleLength)
            SegmentedTubeUtils.checkGuidelines(scene, tube, segmentedTubeRef, partConnectionsValue, movableSection)
            updateSlide(segmentedTubeRef.current)
            handleUpdateAttachmentPoint()
        }
        SegmentedTubeUtils.checkUnsnapSound(
            tube,
            segmentedTubeRef,
            value,
            movableSection,
            maxPossibleLength)
        updateCamera()
        updateInitialMarkerPositionIfMiddle()
    }


    const saveLength = (value: number, forceCM?: boolean, createOriginsAfterSave?: boolean) => {

        //console.log("saveLength")
        //console.log(value, "value coming from slider")

        let convertedValue = value

        if (forceCM || unit === "cm") {
            convertedValue = convertCmToIn(value)
        }

        const notRoundedSegmentsCount = (convertedValue - tube.startSegmentLength - tube.endSegmentLength) / tube.segmentLength
        //console.log(notRoundedSegmentsCount, "notRoundedSegmentsCount")

        // Round to the nearest whole number of segments
        const roundedSegmentsCount = Math.round(notRoundedSegmentsCount)

        // Calculate the adjusted segment length
        const scaledSegmentLength = (convertedValue - tube.startSegmentLength - tube.endSegmentLength) / roundedSegmentsCount

        // Calculate the scale factor
        const segmentScaleFactor = scaledSegmentLength / tube.segmentLength

        //console.log(roundedSegmentsCount, "roundedSegmentsCount")
        //console.log(scaledSegmentLength, "scaledSegmentLength")
        //console.log(segmentScaleFactor, "segmentScaleFactor")

        // Verify our calculations
        const verifiedLength = (roundedSegmentsCount * scaledSegmentLength) + tube.startSegmentLength + tube.endSegmentLength
        //console.log(verifiedLength, "IN: verifiedLength (should be very close to the original value)")
        //console.log(convertInToCm(verifiedLength), "CM: verifiedLength (should be very close to the original value)")

        let segmentedCount = Math.round((convertedValue - tube.startSegmentLength - tube.endSegmentLength) / scaledSegmentLength)
        //console.log(segmentedCount, "segmentedCount")

        if (segmentedCount < 1) {
            segmentedCount = 1
        }

        //end of new logic

        //const newLength = Math.round((value - tube.startSegmentLength - tube.endSegmentLength) / tube.segmentLength)
        let positiveLength = segmentedCount - actualLength.negativeLength
        let negativeLength = actualLength.negativeLength

        if (movableSection === SegmentedTubeSectionType.START) {
            positiveLength = actualLength.positiveLength
            negativeLength = segmentedCount - actualLength.positiveLength
        }
        let mmNewConnections: PartConnectionType[] = []
        if (handleLengthChanges.multipleMove?.partsToMove
            && handleLengthChanges.multipleMove?.partsToMove.length > 0
        ) {
            attachToMove(handleLengthChanges.multipleMove.partsToMove, auxPointRef.current)
            mmNewConnections = detachMarkers(handleLengthChanges.multipleMove!.partsToMove, auxPointRef.current, true, false, true)
            handleLengthChanges.saveMultipleMoveLength(mmNewConnections)
            updateTubeValues(tube.id, (t) => {
                t.length = positiveLength
                t.lengthNegativeSide = negativeLength
                t.scaledSegmentLength = scaledSegmentLength
                t.segmentScaleFactor = segmentScaleFactor
                t.unitRealValue = value
                t.partUnits = forceCM ? "cm" : unit ?? "in"
            })
        } else {
            const newPositions: string[] = []
            if (tube.length < positiveLength) {
                arrayRange(tube.length, positiveLength - 1, 1).forEach(number => newPositions.push(number.toString()))
            }
            if (tube.lengthNegativeSide < negativeLength) {
                arrayRange(-(tube.lengthNegativeSide + 1), -negativeLength, -1).forEach(number => newPositions.push(number.toString()))
            }

            const { newConnections, } = SegmentedTubeUtils.checkSnapOnEditionMovableSection(
                scene,
                tube,
                segmentedTubeRef,
                partConnectionsValue,
                connectionTypes,
                connections,
                movableSection,
                newPositions,
                closestMarkers.current,
                true
            )

            multipleUpdate([{
                id: tube.id,
                updater: (p) => {
                    if (p.type === PartTypeEnum.segmentedTube) {
                        p.length = positiveLength
                        p.lengthNegativeSide = negativeLength
                        p.scaledSegmentLength = scaledSegmentLength
                        p.segmentScaleFactor = segmentScaleFactor
                        p.unitRealValue = value
                        p.partUnits = forceCM ? "cm" : unit ?? "in"
                    }
                },
            },], [...newConnections,])
            updateMarkersClosest()
            if (createOriginsAfterSave) {
                //this is used for when setting up the units initially
                //updating this updates the markers

                setActualLength({ positiveLength, negativeLength, })
                //console.log("createOriginsAfterSave")
                SegmentedTubeUtils.createInstancedMeshOrigins(
                    tube,
                    segmentedTubeRef,
                    middlePartInstancedMeshProvider,
                    startPartInstancedMeshProvider,
                    endPartInstancedMeshProvider,
                    positiveLength,
                    negativeLength,
                    segmentScaleFactor,
                    moonTexture,
                    getColor(),
                    isAdmin ?? undefined,
                )
            }
        }

        segmentedTubeRef.current.multipleMove.snapped = { start: false, end: false, }
        segmentedTubeRef.current.multipleMove.tempConnections = []
        if (!segmentedTubeRef.current.snapped.end) {
            SegmentedTubeUtils.checkUnsnap(tube, segmentedTubeRef, positiveLength, unsnap, SegmentedTubeSectionType.END)
        }
        if (!segmentedTubeRef.current.snapped.start) {
            SegmentedTubeUtils.checkUnsnap(tube, segmentedTubeRef, negativeLength, unsnap, SegmentedTubeSectionType.START)
        }
        //this is now handled when user is done with length changing
        //SegmentedTubeUtils.setAtachmentPoint(segmentedTubeRef.current.attachmentPoint!, segmentedTubeRef, setAttachmentPoint)
    }

    const setAttachmentPointToRef = useCallback(() => {
        SegmentedTubeUtils.setAtachmentPoint(segmentedTubeRef.current.attachmentPoint!, segmentedTubeRef, setAttachmentPoint)
    }, [segmentedTubeRef.current.attachmentPoint, setAttachmentPoint,])

    const colorMergedMesh = (color: string) => {
        if (segmentedTubeRef.current.mergedMesh && segmentedTubeRef.current.mergedMesh.material) {
            (segmentedTubeRef.current.mergedMesh.material as MeshStandardMaterial).color.set(color)
        }
    }

    const updateColor = (color: string) => {
        if (segmentedTubeRef.current.mergedMesh) {
            colorMergedMesh(color)
        } else {
            const middleOrigins = [...segmentedTubeRef.current.instancedMeshOrigins,]
            const startOrigin = middleOrigins.shift()
            const endOrigin = middleOrigins.pop()

            middlePartInstancedMeshProvider.updateColor(middleOrigins, color)
            endPartInstancedMeshProvider.updateColor(endOrigin ? [endOrigin,] : [], color)
            startPartInstancedMeshProvider.updateColor(startOrigin ? [startOrigin,] : [], color)
        }
    }


    const getColor = () => {
        if (isSelected) {
            return SELECTED_PART_COLOR
        }
        return tube.color
    }

    const originalColor = () => updateColor(getColor())

    const getPartInfo = () => {
        return tube
    }


    const duplicatePart = (position: Vector3, initialMarkerName?: string, markerOffset?: Vector3, rotation?: Quaternion,
        length?: number, lengthNegativeSide?: number, partUnits?: string, unitRealValue?: number, scaledSegmentLength?: number, segmentScaleFactor?: number) => {
        //console.log("duplicatePart", position, tube.id)
        const matchingPart = findPartById(tube.apiTypeId)
        if (!matchingPart) {
            console.warn("part no longer exists and cannot be duplicated", tube.apiTypeId)
            return
        }
        const defaultQuaternion = new Quaternion()
        let newPart: any
        if (matchingPart && position) {
            newPart = {
                part: {
                    ...matchingPart,
                },
                posAndRot: {
                    inner: {
                        pos: position,
                        rot: rotation || defaultQuaternion,
                    },
                    outer: {
                        pos: position,
                        rot: rotation || defaultQuaternion,
                    },
                },
                length,
                lengthNegativeSide,
            }
        }
        if (matchingPart && !position) {
            newPart = {
                part: {
                    ...matchingPart,
                },
                posAndRot: {
                    inner: {
                        pos: new Vector3(0, 0, 0),
                        rot: defaultQuaternion,
                    },
                    outer: {
                        pos: new Vector3(0, 0, 0),
                        rot: defaultQuaternion,
                    },
                },
            }
        }
        newPart.duplicatedFrom = tube.id
        newPart.initialMarkerName = initialMarkerName
        //console.log(initialMarkerName, "duplicate part initialMarkerName")
        if (markerOffset) {
            newPart.markerOffset = markerOffset
        }
        if (partUnits) {
            newPart.part.partUnits = partUnits
        }
        if (unitRealValue) {
            newPart.part.unitRealValue = unitRealValue
        }
        if (scaledSegmentLength) {
            newPart.part.scaledSegmentLength = scaledSegmentLength
        }
        if (segmentScaleFactor) {
            newPart.part.segmentScaleFactor = segmentScaleFactor
        }
        //console.log(newPart, "newPart")
        const { newPartId, } = createPart(newPart)
        return newPartId
    }

    const updateInitialMarkerName = (initialMarkerName: string) => {
        setUItoNone()
        updateTubeValues(tube.id, (t) => {
            t.initialMarkerName = initialMarkerName
        }, true)
        setInitialMarkerState(initialMarkerName)
    }

    const setUItoNone = () => {
        setTubeUI(SEGMENTED_TUBE_UI.NONE)
        updateColor(tube.color)
        setIsSelected(false)
        setInAutofocusMode(false)
    }

    const removeMarkerOffset = () => {
        updateTubeValues(tube.id, (t) => {
            t.markerOffset = undefined
        })
    }

    const updatePositionAndRotation = async (position: Vector3, rotation: Quaternion, markerOffset?: Vector3): Promise<void> => {
        await new Promise((resolve) => {
            requestAnimationFrame(() => {
                //const point1 = performance.now()

                SegmentedTubeUtils.createInstancedMeshOrigins(
                    tube,
                    segmentedTubeRef,
                    middlePartInstancedMeshProvider,
                    startPartInstancedMeshProvider,
                    endPartInstancedMeshProvider,
                    actualLength.positiveLength,
                    0,
                    tube.segmentScaleFactor,
                    moonTexture,
                    getColor(),
                    isAdmin ?? undefined,
                )

                //const point2 = performance.now()
                //console.log(point2 - point1, "time to create instanced mesh origins")

                const attachmentPoint = getAttachmentPointOrigin()

                //console.log(attachmentPoint, "updatePositionAndRotation attachmentPoint")
                //console.log(segmentedTubeRef.current.zeroLengthAttachmentPoint, "updatePositionAndRotation zeroLengthAttachmentPoint")


                const secondaryAttachmentPoint = segmentedTubeRef.current.instancedMeshOrigins[2]
                    ? (segmentedTubeRef.current.instancedMeshOrigins[2].children.length >= 3
                        ? segmentedTubeRef.current.instancedMeshOrigins[2].children[2]
                        : segmentedTubeRef.current.instancedMeshOrigins[2].children[0])
                    : undefined

                SegmentedTubeUtils.setAtachmentPoint(
                    attachmentPoint || secondaryAttachmentPoint!, segmentedTubeRef, setAttachmentPoint
                )

                updateTubeValues(tube.id, (t) => {
                    t.position = position
                    t.rotation = rotation
                    if (markerOffset) {
                        t.markerOffset = markerOffset
                        t.position = markerOffset
                    }
                })

                const point3 = performance.now()

                const createInstancedMeshOrigins = () => {
                    SegmentedTubeUtils.createInstancedMeshOrigins(
                        tube,
                        segmentedTubeRef,
                        middlePartInstancedMeshProvider,
                        startPartInstancedMeshProvider,
                        endPartInstancedMeshProvider,
                        tube.length,
                        tube.lengthNegativeSide,
                        tube.segmentScaleFactor,
                        moonTexture,
                        getColor(),
                        isAdmin ?? undefined,
                    )
                }
                if (markerOffset) {
                    createInstancedMeshOrigins()
                    setPositionAndRotation(markerOffset, rotation)
                    const apPosition = new Vector3(markerOffset.x, markerOffset.y, markerOffset.z)
                    SegmentedTubeUtils.setAtachmentPoint(
                        attachmentPoint || secondaryAttachmentPoint!, segmentedTubeRef, setAttachmentPoint, apPosition
                    )
                } else {
                    createInstancedMeshOrigins()
                    setPositionAndRotation(position, rotation)
                }
                //const point4 = performance.now()
                //console.log(point4 - point3, "time to create instanced mesh origins")
                //console.log(point4 - point1, "totaltime time to create instanced mesh origins")
                updateSlide(segmentedTubeRef.current)

                resolve(undefined)
            })
        })
    }

    const getAllMarkers = () => {
        //console.log(segmentedTubeRef.current, "segmentedTubeRef.current")
        const markers = [
            ...Object.values(segmentedTubeRef.current.startSection).map(marker => marker.outer),
            ...Object.values(segmentedTubeRef.current.endSection).map(marker => marker.outer),
            ...Object.values(segmentedTubeRef.current.middleSection).flatMap(side =>
                Object.values(side).map(marker => marker.outer)
            ),
        ].filter(marker => marker !== undefined)

        // Check if tube.initialMarkerName has an underscore
        if (tube.initialMarkerName.includes("_") && !tube.initialMarkerName.includes("_0")) {
            const [baseMarker, markerNumber,] = tube.initialMarkerName.split("_")
            const zeroMarkerName = `${baseMarker}_0`
            const zeroMarker = segmentedTubeRef.current.instancedMeshOrigins
                .flatMap(origin => origin.children)
                .find(child => child.name === zeroMarkerName)

            if (zeroMarker && zeroMarker.userData.middleRefs) {
                const matchingRef = zeroMarker.userData.middleRefs.find(
                    (ref: { refName: string, }) => ref.refName === tube.initialMarkerName
                )

                if (matchingRef) {
                    const clonedObject = zeroMarker.clone()
                    clonedObject.name = tube.initialMarkerName
                    clonedObject.userData = { ...zeroMarker.userData, middleRefs: [matchingRef,], }

                    // Get the world matrix of the zero marker
                    const worldMatrix = new Matrix4().makeRotationFromQuaternion(zeroMarker.getWorldQuaternion(new Quaternion()))
                    worldMatrix.setPosition(zeroMarker.getWorldPosition(new Vector3()))

                    // Apply the local offset to get the final world position
                    const finalPosition = new Vector3().copy(matchingRef.localOffset)
                        .applyMatrix4(worldMatrix)

                    // Set the position directly (baked in world space)
                    clonedObject.position.copy(finalPosition)

                    // Get the world rotation
                    const worldQuaternion = zeroMarker.getWorldQuaternion(new Quaternion())

                    // Set the rotation directly (baked in world space)
                    clonedObject.quaternion.copy(worldQuaternion)

                    //for debugging, create plane with the rotation of the marker
                    const planeGeometry = new PlaneGeometry(0.01, 0.01)
                    const planeMaterial = new MeshBasicMaterial({
                        color: 0xff0000,
                        side: DoubleSide,
                        wireframe: true,
                        depthTest: false,
                    })

                    const plane = new Mesh(planeGeometry, planeMaterial)
                    plane.visible = false
                    plane.position.copy(clonedObject.position)
                    plane.quaternion.copy(clonedObject.quaternion)
                    plane.name = tube.initialMarkerName
                    //plane.rotateY(MathUtils.degToRad(180))
                    plane.updateMatrix()
                    //scene.add(plane)
                    plane.userData = clonedObject.userData
                    //segmentedTubeRef.current.originPoint?.attach(clonedObject)

                    // No need to update matrix world, as we've set world values directly
                    markers.push(plane)
                }
            }
        }
        //console.log(markers, "markers")
        return markers
    }

    const getMesh = (color?: string) => {
        const mesh = segmentedTubeRef.current.mergedMesh
        if (mesh && color) {
            (mesh.material as MeshBasicMaterial).color.set(color)
        }
        return mesh
    }

    const registerMultiSelectMethods = useRegisterComponent(props.id, {
        updateColor, originalColor, deletePart: removePart,
        getBoundingBox: calcBoundgBox, duplicatePart, getPartInfo, updateInitialMarkerName, setUItoNone,
        updatePositionAndRotation, getAllMarkers, getMesh,
    })

    registerMultiSelectMethods()

    const handleLengthSliderMouseDown = () => {
        setHideAddPartButtons(true)
        if (handleLengthChanges.multipleMove?.partsToMove
            && handleLengthChanges.multipleMove?.partsToMove.length > 0) {
            const markersByDirection = movableSection === SegmentedTubeSectionType.START ? segmentedTubeRef.current.startSection : segmentedTubeRef.current.endSection

            const marker = markersByDirection[getMarkerNumber(handleLengthChanges.multipleMove.sliderMarker)].inner!
            const newWorldPosition = MeshUtils.copyWorldPosition(marker)
            auxPointRef.current.position.copy(newWorldPosition)
            attachToMove(handleLengthChanges.multipleMove.partsToMove, auxPointRef.current)
        }
    }

    useEffect(() => {
        if (undoRedoInProgress && undoRedoChange === "position") {
            setPositionAndRotation(tube.position, tube.rotation)
            updateTransforms()
        }
        if (undoRedoInProgress && undoRedoChange === "length") {
            const positiveLength = tube.length
            const negativeLength = tube.lengthNegativeSide
            setTimeout(() => {
                setActualLength({ positiveLength, negativeLength, })
                SegmentedTubeUtils.createInstancedMeshOrigins(
                    tube,
                    segmentedTubeRef,
                    middlePartInstancedMeshProvider,
                    startPartInstancedMeshProvider,
                    endPartInstancedMeshProvider,
                    tube.length,
                    tube.lengthNegativeSide,
                    tube.segmentScaleFactor,
                    moonTexture,
                    getColor(),
                    isAdmin ?? undefined,
                )
                updateSlide(segmentedTubeRef.current)
                handleUpdateAttachmentPoint()
                updateTransforms()
                updateCamera()
            }, 0)

        }
        if (undoRedoInProgress && undoRedoChange === "rotation") {
            setPositionAndRotation(tube.position, tube.rotation)
            updateTransforms()
            //rotationSliderConfig.onRotationUndoRedo()
        }
        if (undoRedoChange !== "") {
            //console.log(undoRedoChange, "undo redo change")
        }
    }, [undoRedoInProgress,])

    const handleLengthSliderMouseUp = (value: number) => {
        if (handleLengthChanges.multipleMove?.partsToMove
            && handleLengthChanges.multipleMove?.partsToMove.length > 0
        ) {
            const markersByDirection = movableSection === SegmentedTubeSectionType.START ? segmentedTubeRef.current.startSection : segmentedTubeRef.current.endSection
            const marker = markersByDirection[getMarkerNumber(handleLengthChanges.multipleMove!.sliderMarker)].inner!
            const newWorldPosition = MeshUtils.copyWorldPosition(marker)

            auxPointRef.current.position.copy(newWorldPosition)
            detachMarkers(handleLengthChanges.multipleMove!.partsToMove, auxPointRef.current, false)
        }
        saveLength(value)

    }

    const setDirection = (direction: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>) => {
        setMovableSection(direction)
        SegmentedTubeUtils.removeGuidelines(scene, segmentedTubeRef)
    }

    useEffect(() => {
        if (tubeUI === SEGMENTED_TUBE_UI.SLIDER) {
            closestMarkers.current = closestMarkersHook.getClosestMarkers(segmentedTubeRef)
            markerMeshesWithoutMiddleSegments.current = MeshUtils.getMarkerMeshesWithoutMiddleSegments(scene)
        }
        if (tubeUI === SEGMENTED_TUBE_UI.SEGMENTED_SLIDER && multiSelectContext) {
            multiSelectContext.setSelectionMode(false)
        }
        calcBoundgBox()
    }, [tubeUI,])

    const handleSlidePositionChange = () => {
        SegmentedTubeUtils.checkAlignments(scene, tube, segmentedTubeRef, partConnectionsValue, [movableSection,], markerMeshesWithoutMiddleSegments.current)
        const { newConnections, } = SegmentedTubeUtils.checkSnapOnEditionMovableSection(
            scene,
            tube,
            segmentedTubeRef,
            partConnectionsValue,
            connectionTypes,
            connections,
            movableSection,
            [],
            [],
            true,
            markerMeshesWithoutMiddleSegments.current
        )
        if (newConnections.length > 0) {
            SoundHelper.playUnsnap()
        }
    }

    const handleSaveSlideSnap = () => {
        const { newConnections, } = SegmentedTubeUtils.checkSnapOnEditionMovableSection(
            scene,
            tube,
            segmentedTubeRef,
            partConnectionsValue,
            connectionTypes,
            connections,
            movableSection,
            [],
            [],
            true
        )
        multipleUpdate([], newConnections)
    }

    const handleUnitsChange = (value: string) => {
        updateUnit(value as UnitType)
    }


    useEffect(() => {
        if (isSelected) {
            //console.log(segmentedTubeRef.current, "segmentedTubeRef.current")
            //console.log(tube.id, tube, "tube")
            //console.log(tube.id, partConnectionsValue,"partConnectionsValue")
            //console.log(connections, "connections")
            setUiVisible(true)
            setTubeUI(SEGMENTED_TUBE_UI.NONE)
            const timer = setTimeout(() => {
                if (rotationSliderConfig.canRotate()) {
                    setTubeUI(SEGMENTED_TUBE_UI.ROTATION)
                } else {
                    setTubeUI(SEGMENTED_TUBE_UI.SLIDER)
                }
            }, 150)
            return () => clearTimeout(timer)
        } else {
            // Delay hiding UI to allow for any final updates
            const timer = setTimeout(() => {
                setUiVisible(false)
            }, 300)
            return () => clearTimeout(timer)
        }
    }, [isSelected,])

    return <>
        {tubeUI !== SEGMENTED_TUBE_UI.NONE && uiVisible && <SegmentedTubeUI
            tube={tube}
            handleNewAttachmentPoint={handleNewAttachmentPoint}
            middleInstanceMesh={middleMesh}
            startInstanceMesh={startMesh}
            endInstanceMesh={endMesh}
            middleMeshes={markerMeshesWithoutMiddleSegments}
            attachToMove={attachToMove}
            detachMarkers={detachMarkers}
            handleSlideMovement={handleSlideMovement}
            tubeInfo={segmentedTubeRef}
            canEditSlide={canEditSlide}
            saveRotationChanges={saveRotationChanges}
            saveSegmentedTubeSliderChanges={saveSegmentedTubeSliderChanges}
            updateTransforms={updateTransforms}
            onSlidePositionChange={handleSlidePositionChange}
            onSlideSave={handleSaveSlideSnap}
            lengthSliderConfig={{
                value: tube.length + tube.lengthNegativeSide,
                unit: unit ?? "in",
                updateUnit: handleUnitsChange,
                step: 1,
                minMiddles: tube.minMiddles,
                maxMiddles: tube.maxMiddles,
                validMax: maxPossibleLength,
                validMin: minLengthRef.current,
                onchange: handleSliderValueChange,
                segmentLength: tube.segmentLength,
                scaledSegmentLength: tube.scaledSegmentLength,
                startSegmentLength: tube.startSegmentLength,
                endSegmentLength: tube.endSegmentLength,
                unitRealValue: tube.unitRealValue ?? undefined,
                saveLength,
                setHideAddPartButtons,
                onMouseDown: handleLengthSliderMouseDown,
                onMouseUp: handleLengthSliderMouseUp,
                setDirection,
                direction: movableSection,
                setAttachmentPointToRef,

            }}
            rotationSliderConfig={rotationSliderConfig}
            onRemove={removePart}
            setTubeUI={setTubeUI}
            tubeUI={tubeUI}
            boundingBox={boundingBox}
            unsnap={unsnap}
            onDuplicate={multiSelectContext?.duplicateSelectedParts ?? (() => { })}
            setIdsAsHighlightedAndTurnOnControl={multiSelectContext?.setIdsAsHighlightedAndTurnOnControl ?? (() => { })}
            setIsSelected={setIsSelected}
            onRotationSliderChange={multiSelectContext?.onRotationSliderChange ?? (() => { })}
            transformMode={multiSelectContext?.transformMode ?? "off"}
            removeMarkerOffset={removeMarkerOffset}
        />}
        {(isSelected && !hideAddPartButtons)
            && <NewPartButtonsGroup
                partId={tube.id}
                info={segmentedTubeRef}
                length={actualLength.positiveLength + actualLength.negativeLength - 1}
                partConnectionsValue={partConnectionsValue}
                setIsSelected={setIsSelected}
                scene={scene}
                isItemSelected={isItemSelected}
                rotation={tube.rotation}
                position={tube.position}
            />}
        {viewTubeNames && isSelected && (
            <Html
                style={{
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                    padding: "5px",
                    borderRadius: "3px",
                    fontSize: "12px",
                }}
            >
                {tube.id}
            </Html>
        )}
    </>
}

export default SegmentedTube