import { ConnectorMarkerType } from "../../builder/exportables"
import { ObjDictionary } from "../utils/utils"
import { PART_DEFAULT_LENGTH, PART_MAX_LENGTH } from "./defaults"
import {
  CategoryTypeAPI,
  CategoryTypeAPIRaw,
  PartTypeAPI,
  PartTypeAPIRaw,
  SegmentedTubeSectionType,
  SizeAPI
} from "./Types"

export const CategoryAdapter = (category: CategoryTypeAPIRaw): CategoryTypeAPI =>
({
  id: category.id,
  name: category.catName,
  image: category.iconUploader,
  imagesForMaterial: category.uniqueIcons,
})

const getSegmentedTubeConnectionPosition = (position: string | undefined) => {
  if (position === SegmentedTubeSectionType.END) {
    return SegmentedTubeSectionType.END
  }
  if (position === SegmentedTubeSectionType.MIDDLE) {
    return SegmentedTubeSectionType.MIDDLE
  }
  if (position === SegmentedTubeSectionType.START) {
    return SegmentedTubeSectionType.START
  }
  return undefined
}

export const partAdapter = (
  part: PartTypeAPIRaw,
  sizes: ObjDictionary<SizeAPI>
): PartTypeAPI => {
  const connectionsAdapted = [
    part.Connection1,
    part.Connection2,
    part.Connection3,
    part.Connection4,
    part.Connection5,
    part.Connection6,
    part.Connection7,
    part.Connection8,
    part.Connection9,
    part.Connection10,
    part.Connection11,
    part.Connection12,
    part.Connection13,
    part.Connection14,
    part.Connection15,
    part.Connection16,
    part.Connection17,
    part.Connection18,
    part.Connection19,
    part.Connection20,
    part.Connection21,
  ].reduce((acc, current, index) => {
    if (current != null) {
      const connectorId = (index + 1).toString()
      const size = sizes[current.connectionSize]

      if (!size) {
        console.warn(`No size found with id ${current.connectionSize}`)
      }

      const opposite = current.oppositeOf?.opposite
      acc.push({
        size: size!,
        placeholderId: `${ConnectorMarkerType.inner.toString()}${connectorId.toString()}`,
        connectorId: current.connectionType,
        oppositeOf: opposite ? opposite.toString() : undefined,
        fullLeg: current.fullLeg,
        iELength: current.iELenght,
        sameConfigurationAs: current.sameConfigurationAs,
        position: getSegmentedTubeConnectionPosition(current.connectionPosition),
        lateralFacing: current.lateralFacing,
        boundary: current.Boundary,
      })

    }
    return acc
  }, [] as PartTypeAPI["connections"])
  const diameters = connectionsAdapted.reduce((acc, current) => {
    acc[current.connectorId] = {
      inner: current.size.innerDiameter,
      outer: current.size.outerDiameter,
    }
    return acc
  }, {} as NonNullable<PartTypeAPI["diameters"]>)

  const isSegmentedTube = !!part.middleLength && !!part.startLength && !!part.endLength
  if (!isSegmentedTube && (!!part.middleLength || !!part.startLength || !!part.endLength)) {
    throw new Error("Segmented tubes must have Start, End and Middle Length")
  }

  return {
    name: part.FriendlyName,
    defaultLength: PART_DEFAULT_LENGTH,
    maxLength: PART_MAX_LENGTH,
    tube: part.IsTube,
    segmentedTube: isSegmentedTube,
    categories: part.Category,
    colorId: part.Color,
    materialId: part.Material,
    subclassId: part.SubMaterial,
    id: part.id,
    connections: connectionsAdapted,
    connectors: [],
    modelIcon: part.ModelIcon,
    diameters,
    assethPath: part.ModelFile,
    affiliateURL: part.affiliateURL,
    noProvider: part.noProvider,
    furtherInfo: part.furtherInfo,
    cartNotice: part.cartNotice,
    rotationDisabled: part.rotationDisabled,
    segmentLength: part.middleLength,
    startSegmentLength: part.startLength,
    endSegmentLength: part.endLength,
    rotationSteps: part.rotationDegreeSteps,
    maxMiddles: part.maxMiddles,
    minMiddles: part.minMiddles,
    defaultMiddles: part.defaultMiddles,
    hideFromSidebar: part.hideFromSidebar,
    specialRotationMarker: part.specialRotationMarker,
  }
}