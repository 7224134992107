/* eslint-disable max-statements */
/* eslint-disable max-len */
import { MutableRefObject } from "react"
import { BufferGeometry, Line, LineBasicMaterial, Object3D, Quaternion, Scene, Vector3 } from "three"
import { MarkerType, PartTypeEnum, TubeMarkerEnum } from "./Types"
import {
    ConnectorMarkerType,
    ConnectorInternalsType
} from "../components/main/DesignScreen/scene/part/parts/connector/types/types"
import {
    TubeInternalsType
} from "../components/main/DesignScreen/scene/part/parts/tube/types/types"

export type MarkerUserData = {
    userDataType: "MarkerUserData",
    id: string,
    type: MarkerType,
    partId: string,
    partApiId: string,
    partType: PartTypeEnum,
    sizeId: string,
    innerOuter: ConnectorMarkerType,
    iELength: number,
    markerName: string,
}

export const getMarkerUserData = (marker: Object3D) => {
    if (marker.userData.userDataType === "MarkerUserData") {
        return marker.userData as MarkerUserData
    }
    console.error("Invalid user data", { marker, userData: marker.userData, })
    throw new Error("Invalid user data")
}

export const isMarkerUserData = (
    marker: Object3D
): marker is Object3D & { userData: MarkerUserData, } => {
    return marker.userData.userDataType === "MarkerUserData"
}

export const getMarkerRef = (
    scene: Scene,
    partId: string,
    markerName: string,
    withPosition = true,
    replaceWithZero = false
) => {
    let modifiedMarkerName = markerName
    const hasUnderscore = markerName.includes("_")

    if (hasUnderscore) {
        const [baseMarker, markerNumber,] = markerName.split("_")
        modifiedMarkerName = `${baseMarker}_0`
    }

    const markerRef = getMarkerRefRecursive(
        [scene,], partId, modifiedMarkerName, withPosition)

    if (!markerRef) {
        console.error("Marker not found", { partId, modifiedMarkerName, markerName, })
        return undefined
    }

    if (hasUnderscore && markerRef.userData.middleRefs) {
        const matchingRef = markerRef.userData.middleRefs.find(
            (ref: { refName: string, }) => ref.refName === markerName
        )

        if (matchingRef) {
            const clonedObject = new Object3D()
            clonedObject.name = markerName
            clonedObject.userData = { ...markerRef.userData, middleRefs: [matchingRef,], }

            // Clone the position, rotation, and scale
            clonedObject.position.copy(markerRef.position)
            clonedObject.rotation.copy(markerRef.rotation)
            clonedObject.scale.copy(markerRef.scale)

            // Apply the localOffset to set the new position
            const worldPosition = markerRef.localToWorld(new Vector3().copy(matchingRef.localOffset))
            clonedObject.position.copy(worldPosition)

            const worldQuaternion = new Quaternion()
            markerRef.getWorldQuaternion(worldQuaternion)
            clonedObject.quaternion.copy(worldQuaternion)

            return clonedObject
        }
    }

    return markerRef
}

export const getMarkerRefRecursive = (
    objects: Object3D[], partId: string, markerName: string, withPosition: boolean
): Object3D | undefined => {

    if (objects.length > 0) {
        let i = 0
        let ref = undefined
        while (!ref && objects[i]) {
            if (isMarkerUserData(objects[i])
                && objects[i].userData.partId === partId
            ) {
                const objectName = withPosition
                    ? objects[i].name
                    : getPlaceholderIdWithoutPosition(objects[i].name)
                //console.log(objectName, "objectName")
                if (objectName === markerName) {
                    ref = objects[i]
                } else if (objects[i].children.length > 1) {
                    ref = getMarkerRefRecursive(
                        objects[i].children,
                        partId,
                        markerName,
                        withPosition
                    )
                }
            } else {
                ref = getMarkerRefRecursive(objects[i].children, partId, markerName, withPosition)
            }
            i++
        }
        return ref
    } else {
        return undefined
    }
}

export const innerToOuter = (markerName: string) => {
    return markerName.replace(ConnectorMarkerType.inner, ConnectorMarkerType.outer)
}

export const outerToInner = (markerName: string) => {
    return markerName.replace(ConnectorMarkerType.outer, ConnectorMarkerType.inner)
}

export const meshToInner = (markerName: string) => {
    return markerName.replace(ConnectorMarkerType.mesh, ConnectorMarkerType.inner)
}

export const getOppositeTubeMarker = (markerName: TubeMarkerEnum) => {
    return markerName === TubeMarkerEnum.BOTTOM ? TubeMarkerEnum.TOP : TubeMarkerEnum.BOTTOM
}

export const addLines = (
    scene: Scene,
    points: Vector3[],
    internalsRef: MutableRefObject<ConnectorInternalsType> | MutableRefObject<TubeInternalsType>,
) => {
    const { guidelines, } = internalsRef.current
    const geometry = new BufferGeometry().setFromPoints(points)
    const lineMesh = new Line(
        geometry,
        new LineBasicMaterial({ color: "red", linewidth: 1, })
    )
    guidelines.push(lineMesh)
    scene.add(lineMesh)
    return guidelines
}

export const getMarkerNumber = (name: string) => {
    if (isInner(name)) {
        return name.split("inner")[1]
    } else if (isInnerOrOuter(name)) {
        return name.split("outer")[1]
    } else if (isPlus(name)) {
        return name.split("plus")[1]
    } else {
        return name.split("mesh")[1]
    }
}

export const getPlaceholderIdWithoutPosition = (placeholderId: string) => {
    return placeholderId.split("_")[0]
}

export const getSideAndPosition = (markerName: string) => {
    if (markerName.includes("NaN")) {
        return { markerSide: undefined, markerPosition: undefined, }
    }
    if (isInnerOrOuterOrPlus(markerName) || (markerName.includes("mesh") && markerName.includes("_"))) {
        const markerNumber = getMarkerNumber(markerName)
        const markerSide = markerNumber.split("_")[0]
        const markerPosition = Number(markerNumber.split("_")[1])
        return { markerSide, markerPosition, }
    }
    return { markerSide: undefined, markerPosition: undefined, }
}

export const isInner = (markerName: string) => {
    if (markerName.includes(ConnectorMarkerType.inner)) {
        return true
    } else if (markerName.includes(ConnectorMarkerType.outer)) {
        return false
    }
}

export const isInnerOrOuter = (markerName: string) => {
    return markerName.includes(ConnectorMarkerType.inner)
        || markerName.includes(ConnectorMarkerType.outer)
}

export const isInnerOrOuterOrPlus = (markerName: string) => {
    return markerName.includes(ConnectorMarkerType.inner)
        || markerName.includes(ConnectorMarkerType.outer)
        || markerName.includes(ConnectorMarkerType.plus)
}

export const isPlus = (markerName: string) => {
    if (markerName.includes(ConnectorMarkerType.plus)) {
        return true
    } else {
        return false
    }
}

export const plusName = (markerName: string) => {
    if (markerName.includes(ConnectorMarkerType.plus)) {
        return true
    } else {
        return false
    }
}



export const MAX_POSSIBLE_LENGTH_REDUCTION = 0.1